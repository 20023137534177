import React from 'react';
import PropTypes from 'prop-types';

const TitleImg = ({ title, titleClass, imgSrc, imgClass }) => {
    return (
        <div className="columns is-centered">
        <div className="column is-narrow has-text-centered">
          <div className="flex-container">
            <h2 className={`title page-title ${titleClass}`}>{title}</h2>
            <img className={`title-img ${imgClass}`} src={imgSrc} alt={title} />
          </div>
        </div>
      </div>
    )
}

TitleImg.propTypes = {
    title: PropTypes.string,
    titleClass: PropTypes.string,
    imgSrc: PropTypes.string,
    imgClass: PropTypes.string
}

export default TitleImg;
