import * as React from 'react';

import whatsapp from '../../img/social/whatsapp-white.svg';
import TitleImg from '../../components/TitleImg';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, submitted: false };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => {
        this.setState({ submitted: true });
      })
      .catch(() => alert('Por favor preencha este campo'));
  };

  resetForm = () => {
    this.setState({ submitted: false });
  };

  render() {
    return (
      <section className="section section-gradient contact-section footer-contact">
        <div className="container">
          <div className="content">
            <div className="columns is-centered is-vcentered">
              <div className="column is-7">
                <TitleImg
                  title={'Contacto'}
                  titleClass={'contact-title'}
                  imgSrc={'/img/telephone.png'}
                  imgClass={'contact-title-img'}
                />

                <div className="contact-options">
                  <span className="contact-whatsapp">
                    <p>Contacte-nos por:</p>
                    <a
                      className="cta-button contact-item whatsapp-btn"
                      title="whatsapp"
                      href="https://wa.me/+351919038198"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={whatsapp}
                        alt="WhatsApp"
                        style={{ width: '1.5em', height: '1.5em' }}
                      />
                      WhatsApp
                    </a>
                  </span>
                  <hr className='contact-hr'/>
                  <div className="contact-email">Ou Email:</div>
                </div>
                {this.state.submitted ? (
                  <div className="thanks-message">
                    <h2>Obrigado!</h2>
                    <p>A sua mensagem foi enviada com sucesso.</p>

                    <button className="button is-link" onClick={this.resetForm}>
                      Enviar outro email
                    </button>
                  </div>
                ) : (
                  <form
                    name="contact"
                    method="post"
                    action="/contact/thanks/"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                    onSubmit={this.handleSubmit}
                  >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact" />
                    <div hidden>
                      <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                      </label>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'name'}>
                        Nome
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'text'}
                          name={'name'}
                          onChange={this.handleChange}
                          id={'name'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'email'}>
                        Email
                      </label>
                      <div className="control">
                        <input
                          className="input"
                          type={'email'}
                          name={'email'}
                          onChange={this.handleChange}
                          id={'email'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label" htmlFor={'message'}>
                        Mensagem
                      </label>
                      <div className="control">
                        <textarea
                          className="textarea"
                          name={'message'}
                          onChange={this.handleChange}
                          id={'message'}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="field">
                      <button className="button is-link" type="submit">
                        Enviar
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
